<!--
 * @Description: 课程商城
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:44:31
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-01 11:20:52
-->
<template>
  <div class="course-shop">
    <div class="btns">
      <el-button type="primary" @click="toCollect">课程收藏夹</el-button>
    </div>
    <div class="nav">
      <el-tabs v-model="activeFirst" class="nav-first" @tab-click="handleFirstNavClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane
          v-for="item in navList"
          :key="item.productCategoryId"
          :label="item.name"
          :name="item.productCategoryId + ''"
        ></el-tab-pane>
      </el-tabs>
      <el-tabs
        v-if="secondNavList.length"
        v-model="activeSecond"
        class="nav-second"
        @tab-click="handleSecondNavClick"
      >
        <el-tab-pane
          v-for="item in secondNavList"
          :key="item.productCategoryId"
          :label="item.name"
          :name="item.productCategoryId + ''"
        ></el-tab-pane>
      </el-tabs>
      <el-tabs
        v-if="thirdNavList.length"
        v-model="activeThird"
        class="nav-third"
        @tab-click="handleThirdNavClick"
      >
        <el-tab-pane
          v-for="item in thirdNavList"
          :key="item.productCategoryId"
          :label="item.name"
          :name="item.productCategoryId + ''"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="filter">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          按销量排序<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="handleSaleOrder(0)">从高到低</div></el-dropdown-item>
          <el-dropdown-item><div @click="handleSaleOrder(1)">从低到高</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          按价格排序<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="handlePriceOrder(0)">从高到低</div></el-dropdown-item>
          <el-dropdown-item><div @click="handlePriceOrder(1)">从低到高</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          授课方式<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="handleTypeOrder(1)">直播</div></el-dropdown-item>
          <el-dropdown-item><div @click="handleTypeOrder(0)">录播</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-checkbox v-model="isFree" @change="handleIsFreeChange">只看免费课程</el-checkbox> -->
      <div class="search">
        <el-input
          v-model="keyword"
          placeholder="请输入您想搜索的课程名称"
          clearable
          type="text"
        ></el-input>
        <div class="btn" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="courseShopList.length">
        <div class="clearfix">
          <div
            v-for="item in courseShopList"
            :key="item.productId"
            class="course-item"
            @click="toDetail(item.productId)"
          >
            <div class="badge">
              <img
                :src="
                  [require('@/assets/shop/lubo_icon.png'), require('@/assets/shop/zhibo_icon.png')][
                    item.courseType
                  ]
                "
                alt=""
              />
            </div>
            <div class="cover">
              <img :src="item.pic" alt="" />
              <div v-if="item.courseType == 1 && item.liveStatus == 1" class="isLiveing">
                <img src="@/assets/shop/liveing.gif" alt="" />
                <span>直播中</span>
              </div>
            </div>
            <div class="text">
              <div class="name">{{ item.name }}</div>
              <div class="info">
                {{ item.chapterCount }}节丨{{ item.durationTotal }}分钟丨{{ item.organizationName }}
              </div>
              <div class="price">
                <span>￥{{ item.price }}</span>
                <span>{{ item.sale }}人购买</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="empty">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { courseNav, courseShopList } from '@/api/course'
import to from 'await-to'
export default {
  name: 'CourseShop',
  data() {
    return {
      courseShopList: [],
      navList: [],
      secondNavList: [],
      thirdNavList: [],
      activeFirst: '-1',
      activeSecond: '',
      activeThird: '',
      productCategoryId: '',
      isFree: 0,
      keyword: '',
      pager: {
        total: 0,
        size: 15,
        current: 1,
      },
      orderSeq: '',
      orderType: '',
      courseType: '',
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'CourseShopDetail') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    window.removeEventListener('scroll', this.handlePaneListener)
    next()
  },
  mounted() {
    this.getNavList()
    this.getCourseList()
    window.addEventListener('scroll', this.handlePaneListener)
  },

  methods: {
    //给页面添加监听
    handlePaneListener() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight) {
        this.pager.current = this.pager.current + 1
        this.getCourseList()
      }
    },
    async getNavList() {
      const [res, err] = await to(courseNav({}))
      if (err) return this.$message.warning(err.msg)
      this.navList = res.data
    },
    async getCourseList() {
      const { productCategoryId, orderSeq, orderType, courseType } = this
      const { current, size } = this.pager
      const params = { orderSeq, orderType, courseType, keyword: this.keyword.trim() }
      const [res, err] = await to(courseShopList({ current, size, productCategoryId, ...params }))
      if (err) return this.$message.warning(err.msg)
      res.data.list.forEach(item => {
        this.courseShopList.push(item)
      })
    },
    handleFirstNavClick(item) {
      this.courseShopList = []
      this.pager.current = 1
      this.activeSecond = ''
      this.activeThird = ''
      this.secondNavList = item.index == 0 ? [] : this.navList[item.index - 1].children
      this.thirdNavList = []
      this.productCategoryId = item.index == 0 ? '' : this.navList[item.index - 1].productCategoryId
      this.getCourseList()
    },
    handleSecondNavClick(item) {
      this.courseShopList = []
      this.pager.current = 1
      this.thirdNavList = this.secondNavList[item.index].children
      this.productCategoryId = this.secondNavList[item.index].productCategoryId
      this.getCourseList()
    },
    handleThirdNavClick(item) {
      this.courseShopList = []
      this.pager.current = 1
      this.productCategoryId = this.thirdNavList[item.index].productCategoryId
      this.getCourseList()
    },
    handleOrder() {
      this.courseShopList = []
      this.pager.current = 1
      this.getCourseList()
    },
    handleSaleOrder(orderSeq) {
      this.courseShopList = []
      this.pager.current = 1
      this.orderSeq = orderSeq
      this.orderType = 1
      this.getCourseList()
    },
    handlePriceOrder(orderSeq) {
      this.courseShopList = []
      this.pager.current = 1
      this.orderSeq = orderSeq
      this.orderType = 0
      this.getCourseList()
    },
    handleTypeOrder(courseType) {
      this.courseShopList = []
      this.pager.current = 1
      this.courseType = courseType
      this.getCourseList()
    },
    handleIsFreeChange() {
      this.courseShopList = []
      this.pager.current = 1
      this.isFree = this.isFree ? 1 : 0
      this.getCourseList()
    },
    search() {
      if (this.keyword == '') {
        this.$message.warning('请输入搜索的课程名称')
        return false
      }
      this.courseShopList = []
      this.pager.current = 1
      this.getCourseList()
    },
    toDetail(productId) {
      this.$router.push(`/course/shop/${productId}`)
    },
    toCollect() {
      this.$router.push(`/course/collect`)
    },
  },
}
</script>
<style lang="scss" scoped>
.course-shop {
  width: 100%;
  .btns {
    text-align: right;
    margin-bottom: 20px;
  }
  .nav {
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    ::v-deep.el-tabs {
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__header {
        margin: 0;
      }
    }
    ::v-deep.nav-first {
      background: #ffffff;
      height: 60px;
      line-height: 60px;
      padding: 0 10px;
      .el-tabs__item {
        padding: 0 12px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        color: #333333;
        margin: 0 5px;
        transition: all 0.3s;
        &:hover {
          color: #ff7b33;
        }
        &.is-active {
          background: #ff7b33;
          color: #ffffff;
        }
      }
      .el-tabs__header {
        margin-bottom: 10px;
      }
    }
    ::v-deep.nav-second,
    ::v-deep.nav-third {
      padding: 0 20px;
      .el-tabs__nav {
        line-height: 30px;
      }
      .el-tabs__item {
        height: 30px;
      }
    }
  }
  .filter {
    height: 58px;
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    ::v-deep.el-dropdown {
      cursor: pointer;
      margin-right: 40px;
    }
    .search {
      margin-left: auto;
      position: relative;
      width: 240px;
      height: 30px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #ff7b33;
      border-radius: 16px;
      ::v-deep input {
        box-sizing: border-box;
        width: 240px;
        height: 30px;
        border-radius: 16px;
        border: 0;
        background-color: #f5f5f5;
        outline: none;
        padding: 0 60px 0 10px;
      }
      .btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 30px;
        background: #ff7b33;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 24px 0 0 24px;
    background: #ffffff;
    .course-item {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      float: left;
      width: 282px;
      height: 268px;
      border-radius: 4px;
      box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
      margin-right: 23px;
      margin-bottom: 24px;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-5px);
      }
      .cover {
        position: relative;
        width: 282px;
        height: 145px;
        img {
          @extend %width-height;
        }
        .isLiveing {
          position: absolute;
          right: 5px;
          bottom: 6px;
          width: 56px;
          height: 22px;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 12px;
          line-height: 22px;
          img {
            width: 10px;
            height: 10px;
            margin-right: 3px;
          }
        }
      }
      .text {
        box-sizing: border-box;
        width: 282px;
        height: 123px;
        padding: 14px 10px;
        display: flex;
        flex-direction: column;
        .name {
          font-size: 14px;
          height: 42px;
          font-weight: 500;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .info {
          font-size: 12px;
          color: #666666;
          height: 12px;
        }
        .price {
          margin-top: auto;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          span {
            &:nth-of-type(1) {
              font-size: 24px;
              color: #ff583e;
            }
            &:nth-of-type(2) {
              line-height: 12px;
              font-size: 12px;
              color: #666666;
            }
          }
        }
      }
      .badge {
        z-index: 9;
        position: absolute;
        width: 56px;
        height: 22px;
        left: 10px;
        top: 10px;
        img {
          @extend %width-height;
        }
      }
    }
    .empty {
      text-align: center;
      font-size: 14px;
      padding-bottom: 20px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .el-pagination {
    padding-bottom: 20px;
  }
}
</style>
